import React, { useEffect, useState, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, TextField, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { toast } from 'react-toastify';
import ORBApi from '../../../../services/ORBApi';
import CpfCnpj from '../../../../components/common/CpfCnpj';
import Loading from '../../../../components/common/Loading';
import { getTranslatedTexts } from '../../../../utils/TranslationUtils';
import SessionContext from '../../../../contexts/SessionContext';

const RegisterContributors = (props) => {
  const [contributor, setContributor] = useState({
    name: '',
    artist_name: '',
    document: '',
  });

  let {
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);

  useEffect(() => {
    const translatedTexts = getTranslatedTexts('holders', language_preference);
    setTexts(translatedTexts);
  }, [language_preference]);

  return (
    <div>
      <h4>{texts ? texts.form_texts.title : ''}</h4>
      <hr />
      <TableContainer
        component={Paper}
        style={{ marginBottom: '30px', maxHeight: '250px' }}
      >
        <Table
          sx={{
            height: 'max-content',
          }}
        >
          <TableHead>
            <TableRow>
              {texts.headers_table.map((title) => (
                <TableCell align="center"> {title}</TableCell>
              ))}
              <TableCell align="center"> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.contributorsData.map((contributor) => {
              return (
                <TableRow
                  key={contributor.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {contributor.name}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {contributor.document}
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    {contributor.artist_name}
                  </TableCell>
                  <TableCell align="center">
                    <AddIcon
                      className="clickable"
                      color="secondary"
                      onClick={() => {
                        setContributor({
                          name: contributor.name,
                          document: contributor.document,
                          artist_name: contributor.artist_name,
                        });
                        props.setContributorsData(
                          props.contributorsData.filter(
                            (item) => item.id !== contributor.id,
                          ),
                        );
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <ContributorForm
        form_texts={texts.form_texts}
        contributor={contributor}
        setContributor={setContributor}
      />
    </div>
  );
};

function ContributorForm(props) {
  const [title, setTitle] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const saveContributor = () => {
    if (props.contributor.name === '') {
      toast.warn('Por favor, preencha todos os dados!');
      return;
    }
    setIsSaving(true);
    ORBApi.postContributors(props.contributor)
      .then(() => {
        toast.success('Titular Salvo!');
        props.setContributor({ name: '', artist_name: '', document: '' });
      })
      .catch(() => {
        toast.error('Falha ao salvar Titular.');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <>
      <h4>{title}</h4>
      <hr />
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            label={props.form_texts.name}
            variant="outlined"
            fullWidth
            value={props.contributor.name}
            onChange={(e) =>
              props.setContributor({
                ...props.contributor,
                name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={props.form_texts.artist_name}
            variant="outlined"
            fullWidth
            value={props.contributor.artist_name}
            onChange={(e) =>
              props.setContributor({
                ...props.contributor,
                artist_name: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <CpfCnpj
            label={props.form_texts.doc}
            value={props.contributor.document}
            onChange={(e) =>
              props.setContributor({
                ...props.contributor,
                document: e.target.value.replace(/\D/g, ''),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={6} style={{ marginTop: '15px' }}>
        {!isSaving ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={saveContributor}
          >
            {' '}
            Salvar{' '}
          </Button>
        ) : (
          <Loading />
        )}
      </Grid>
    </>
  );
}

export default RegisterContributors;
