import React, { useEffect, useState, useContext } from 'react';
import './styles.css';
import ORBNavBar from '../../../components/common/ORBNavBar';
import ORBApi from '../../../services/ORBApi';
import Backdrop from '@mui/material/Backdrop';
import Loading from '../../../components/common/Loading';
import { Modal } from '@material-ui/core';
import ActionBar, { defaultFilters } from './components/ActionBar';
import ContributorsTable from './components/ContributorsTable';
import ContributorsForm from './components/ContributorsForm';
import SessionContext, { SystemRoles } from '../../../contexts/SessionContext';
import { getTranslatedTexts } from '../../../utils/TranslationUtils';

const Contributors = () => {
  const [data, setData] = useState();

  const [filters, setFilters] = useState(defaultFilters);
  const [formVisible, setFormVisible] = useState(false);
  const [contributorOnPlay, setContributorOnPlay] = useState(null);
  let {
    hasRole,
    session_data: { language_preference },
  } = useContext(SessionContext);
  const [texts, setTexts] = useState(null);

  const onFilterChangeHandler = (filters) => {
    setFilters(filters);
  };

  const onPageChangeHandler = (page) => {
    if (page !== data.current_page) loadData(page);
  };

  const showContributorForm = (contributor) => {
    if (contributor)
      setContributorOnPlay(JSON.parse(JSON.stringify(contributor)));
    // do not shallow copy the objects, have to clone it completetly to avoid applying unsaved changes
    else setContributorOnPlay(null);
    setFormVisible(true);
  };

  const onSaveContributor = () => {
    closeForm();
    loadData(filters.page);
  };

  const loadData = (page) => {
    ORBApi.loadContributors(page, filters).then((data) => {
      setData(data);
    });
  };

  const closeForm = () => {
    setFormVisible(false);
    setContributorOnPlay(null);
  };

  useEffect(() => {
    loadData(1);
  }, [filters]);

  useEffect(() => {
    const translatedTexts = getTranslatedTexts('holders', language_preference);
    setTexts(translatedTexts);
  }, [language_preference]);

  return (
    <>
      <div id="register-contributors-page">
        <ORBNavBar page_title={texts?.title_holders} />
        <ActionBar
          input_filter={texts ? texts.input_filter : ''}
          total_holders={texts ? texts.total_holders : ''}
          form_texts={texts ? texts.form_texts : {}}
          formVisible={formVisible}
          setFormVisible={setFormVisible}
          onFilterChange={onFilterChangeHandler}
          total_items={data?.total_items}
          onSaveContributor={onSaveContributor}
        />

        {!data ? (
          <Loading
            className="contributors-loading-container"
            text={texts?.loading}
          />
        ) : (
          <>
            <ContributorsTable
              headers_table={texts ? texts.headers_table : []}
              contributors={data.contributors}
              total_items={data.total_items}
              page={data.page}
              pages={data.pages}
              current_page={data.current_page}
              onPageChange={onPageChangeHandler}
              showContributorForm={showContributorForm}
            />
            <Modal
              size="lg"
              open={formVisible}
              onClose={closeForm}
              BackdropComponent={Backdrop}
              BackdropProps={{ timeogut: 500 }}
            >
              <div className="modal-container">
                <ContributorsForm
                  messages={texts ? texts.messages : {}}
                  form_texts={texts?.form_texts}
                  edit_contributors={contributorOnPlay}
                  onSaveContributor={onSaveContributor}
                />
              </div>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default Contributors;
